import React, { useEffect, useState, useRef } from 'react';
import '../KanbanBoard.css';
import AUTH_BASE_URL from '../../../../util/AUTH_BASE_URL';
import KanbanCardBack from './Back';
import KanbanCardFront from './Front';

const KanbanCard = ({ task, columns, setEditingTaskId, isEditing, isLast, fetchData, handleDeleteTask }) => {
    const [user, setUser] = useState(null);
    const cardInnerRef = useRef(null);
    const columnScrollPosition = useRef(0);
    const [editableFields, setEditableFields] = useState({});
    const [showOverlay, setShowOverlay] = useState(false);
    const [cachedUsers, setCachedUsers] = useState({});
    const [error, setError] = useState(null);

    const assigneeColumnId = Object.keys(columns).find(
        columnId => columns[columnId].title === "Assignee"
    );

    const stateColumnId = Object.keys(columns).find(
        columnId => columns[columnId].title === "State"
    );

    const assignee = editableFields[assigneeColumnId] || "Unassigned";
    const taskState = editableFields[stateColumnId]?.toLowerCase() || "";

    useEffect(() => {
        if (assignee && !cachedUsers[assignee]) {
            const firstName = assignee.split(' ')[0];
            setUser(null);
            if (firstName === "Unassigned") {
                return;
            }
            fetch(`${AUTH_BASE_URL}auth/user/name?name=${firstName}`)
                .then(response => {
                    if (!response.ok) throw new Error('Network response was not ok');
                    return response.json();
                })
                .then(data => {
                    setUser(data);
                    setCachedUsers(prev => ({ ...prev, [assignee]: data }));
                })
                .catch(error => console.error('Error fetching user:', error));
        } else if (cachedUsers[assignee]) {
            setUser(cachedUsers[assignee]);
        }
    }, [assignee, cachedUsers]);

    useEffect(() => {
        const fields = {};
        task?.cells.forEach(cell => {
            fields[cell.columnId] = cell.value || '';
        });
        setEditableFields(fields);
    }, [task]);

    const handleFieldChange = (columnId, value) => {
        setEditableFields(prevFields => ({
            ...prevFields,
            [columnId]: value,
        }));

        if (columnId === assigneeColumnId) {
            setUser(null);
            const firstName = value.split(' ')[0];
            fetch(`${AUTH_BASE_URL}auth/user/name?name=${firstName}`)
                .then(response => {
                    if (!response.ok) throw new Error('Network response was not ok');
                    return response.json();
                })
                .then(data => {
                    setUser(data);
                    setCachedUsers(prev => ({ ...prev, [value]: data }));
                })
                .catch(error => console.error('Error fetching user:', error));
        }
    };

    const handleSave = async () => {
        try {
            handleCancel();
            const updatedCells = Object.keys(editableFields).map(columnId => ({
                columnId: parseInt(columnId),
                value: editableFields[columnId],
            }));

            // Make the API call to save the task
            const response = await fetch(`${AUTH_BASE_URL}projects/planning/save-task`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ taskId: task?.id, cells: updatedCells }),
            });

            if (!response.ok) throw new Error('Network response was not ok');

            // Refresh the data after saving
            fetchData();

            // Trigger the fade-out animation
            const overlay = document.querySelector('.kanban-overlay-small');
            if (overlay) {
                overlay.classList.remove('fade-in');
                overlay.classList.add('fade-out');
            }

            // Wait for the fade-out animation to complete (500ms)
            setTimeout(() => {
                setShowOverlay(false);
                setEditingTaskId(null);
                if (overlay) {
                    overlay.classList.remove('fade-out');
                }
                restoreColumnScrollPosition();

                // Scroll to the top smoothly
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }, 500);
        } catch (error) {
            console.error('Error updating task:', error);
            setError('Failed to update the backend. Please try again.');
        }
    };


    const handleDelete = async () => {
        try {
            handleCancel();
            const response = await fetch(`${AUTH_BASE_URL}projects/planning/delete-task`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ taskId: task.id }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete task');
            }

            handleDeleteTask(task.id);
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    const handleEdit = () => {
        const columnContainer = cardInnerRef.current.closest('.kanban-column');
        if (columnContainer) {
            columnScrollPosition.current = columnContainer.scrollTop;
        }
        setShowOverlay(true);
        setEditingTaskId(task.id);
        setTimeout(() => {
            document.querySelector('.kanban-overlay-small')?.classList.add('fade-in');
        }, 1000);
    };

    const handleCancel = () => {
        document.querySelector('.kanban-overlay-small')?.classList.remove('fade-in');
        document.querySelector('.kanban-overlay-small')?.classList.add('fade-out');

        setTimeout(() => {
            setShowOverlay(false);
            setEditingTaskId(null);
            document.querySelector('.kanban-overlay-small')?.classList.remove('fade-out');
        }, 500);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const restoreColumnScrollPosition = () => {
        const columnContainer = cardInnerRef.current.closest('.kanban-column');
        if (columnContainer) {
            columnContainer.scrollTo({
                top: columnScrollPosition.current,
                behavior: 'smooth',
            });
        }
    };

    if (!task) {
        return null;
    }

    return (
        <>
            {showOverlay && <div className="kanban-overlay-small" />}
            <div
                className={`kanban-card small-new ${isEditing ? 'editing' : ''}`}
                style={{
                    marginBottom: isLast ? '240px' : '0px',
                    border: 0
                }}
                ref={cardInnerRef}
            >
                <div className="kanban-card-inner">
                    {!isEditing ? (
                        <KanbanCardFront
                            editableFields={editableFields}
                            columns={columns}
                            user={user}
                            handleEdit={handleEdit}
                        />
                    ) : (
                        <KanbanCardBack
                            task={task}
                            columns={columns}
                            editableFields={editableFields}
                            handleFieldChange={handleFieldChange}
                            handleCancel={handleCancel}
                            setEditableFields={setEditableFields}
                            fetchData={fetchData}
                            handleSave={handleSave}
                            handleDelete={handleDelete}
                            setEditingTaskId={setEditingTaskId}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default KanbanCard;
