import React, { useEffect, useState, useRef } from 'react';
import '../KanbanBoard.css';
import AUTH_BASE_URL from '../../../../util/AUTH_BASE_URL';
import KanbanCardBack from './Back';
import KanbanCardFront from './Front';
import {getGradientColors} from "../../../../util/helpers/getGradientColors";

const KanbanCard = ({ task, columns, setEditingTaskId, isEditing, isLast, fetchData, handleDeleteTask, selectedAssignee }) => {
    const [user, setUser] = useState(null);
    const cardInnerRef = useRef(null);
    const columnScrollPosition = useRef(0);
    const [editableFields, setEditableFields] = useState({});
    const [showOverlay, setShowOverlay] = useState(false);
    const [cachedUsers, setCachedUsers] = useState({});
    const [error, setError] = useState(null);
    const originalOverflowRef = useRef();

    // State to track hover status
    const [isHovered, setIsHovered] = useState(false);

    const assigneeColumnId = Object.keys(columns).find(
        columnId => columns[columnId].title === "Assignee"
    );

    const stateColumnId = Object.keys(columns).find(
        columnId => columns[columnId].title === "State"
    );

    const assignee = editableFields[assigneeColumnId] || "Unassigned";
    const taskState = editableFields[stateColumnId]?.toLowerCase() || "";

    const getMonthsDifference = (date) => {
        const currentDate = new Date();
        const taskCreatedDate = new Date(date);

        // Normalize both dates to the first day of the month (ignore time zones)
        const normalizedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const normalizedTaskCreatedDate = new Date(taskCreatedDate.getFullYear(), taskCreatedDate.getMonth(), 1);

        // Calculate the difference in months
        return (normalizedCurrentDate.getFullYear() - normalizedTaskCreatedDate.getFullYear()) * 12
            + (normalizedCurrentDate.getMonth() - normalizedTaskCreatedDate.getMonth());
    };

    // Get the blur level based on task creation date and state
    const calculateBlurLevel = () => {
        const createdDate = task?.modifiedAt;
        const monthsOld = getMonthsDifference(createdDate);

        if (taskState === 'closed' || taskState === 'parking lot') {
            return 0; // No blur for these states
        }

        if (taskState === 'in progress' && monthsOld > 1) {
            return 1;
        } else if (monthsOld >= 1 && monthsOld <= 3 && taskState !== 'in progress') {
            return 4;
        } else if (monthsOld > 3 && monthsOld <= 5 && taskState !== 'in progress') {
            return 7;
        } else if (monthsOld > 6 && taskState !== 'in progress') {
            return 10;
        }
        return 0;
    };

    const blurLevel = calculateBlurLevel();

    let borderStyle = {
        borderLeftColor: '#0b0b0b',
        borderWidth: '4px',
        borderStyle: 'solid',
        borderRadius: '8px'
    };

    if (taskState === 'closed' || taskState === 'parking lot') {
        borderStyle = {
            borderImage: 'linear-gradient(to right, grey, grey) 1',
        };
    } else if (taskState === 'new') {
        borderStyle = {
            borderImage: 'linear-gradient(to right, black, black) 1',
        };
    } else if (assignee) {
        const gradientColors = getGradientColors(assignee);
        borderStyle = {
            borderImage: `linear-gradient(${gradientColors[0]}, ${gradientColors[1]}) 1`,
        };
    }

    // Event handlers for hover state
    const handleMouseEnter = () => {
        setIsHovered(true); // Set hover state to true
    };

    const handleMouseLeave = () => {
        setIsHovered(false); // Reset hover state
    };

    useEffect(() => {
        if (assignee && !cachedUsers[assignee]) {
            const firstName = assignee.split(' ')[0];
            setUser(null);
            if (firstName === 'Unassigned') return;
            fetch(`${AUTH_BASE_URL}auth/user/name?name=${firstName}`)
                .then(response => {
                    if (!response.ok) throw new Error('Network response was not ok');
                    return response.json();
                })
                .then(data => {
                    setUser(data);
                    setCachedUsers(prev => ({ ...prev, [assignee]: data }));
                })
                .catch(error => console.warn('Error fetching user:', error));
        } else if (cachedUsers[assignee]) {
            setUser(cachedUsers[assignee]);
        }
    }, [assignee, cachedUsers]);

    useEffect(() => {
        const fields = {};
        task?.cells.forEach(cell => {
            fields[cell.columnId] = cell.value || '';
        });
        setEditableFields(fields);
    }, [task]);

    useEffect(() => {
        if (isEditing && assignee === "Unassigned") {
            originalOverflowRef.current = document.body.style.overflow;
            document.body.style.overflow = 'hidden';
        } else if (originalOverflowRef.current !== undefined) {
            document.body.style.overflow = originalOverflowRef.current;
            originalOverflowRef.current = undefined;
        }
    }, [isEditing, assignee]);

    const handleFieldChange = (columnId, value) => {
        setEditableFields(prevFields => ({
            ...prevFields,
            [columnId]: value,
        }));

        if (columnId === assigneeColumnId) {
            setUser(null);
            const firstName = value.split(' ')[0];
            fetch(`${AUTH_BASE_URL}auth/user/name?name=${firstName}`)
                .then(response => {
                    if (!response.ok) throw new Error('Network response was not ok');
                    return response.json();
                })
                .then(data => {
                    setUser(data);
                    setCachedUsers(prev => ({ ...prev, [value]: data }));
                })
                .catch(error => console.error('Error fetching user:', error));
        }
    };

    const handleSave = async () => {
        try {
            const updatedCells = Object.keys(editableFields).map(columnId => ({
                columnId: parseInt(columnId),
                value: editableFields[columnId],
            }));

            setShowOverlay(false);
            setEditingTaskId(null);
            restoreColumnScrollPosition();

            const response = await fetch(`${AUTH_BASE_URL}projects/planning/save-task`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ taskId: task?.id, cells: updatedCells }),
            });

            if (!response.ok) throw new Error('Network response was not ok');

            fetchData();
        } catch (error) {
            console.error('Error updating task:', error);
            setError('Failed to update the backend. Please try again.');
        }
    };

    const handleDelete = async () => {
        try {
            handleCancel();
            const response = await fetch(`${AUTH_BASE_URL}projects/planning/delete-task`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ taskId: task.id }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete task');
            }

            handleDeleteTask(task.id);
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    const handleEdit = () => {
        const columnContainer = cardInnerRef.current.closest('.kanban-column');
        if (columnContainer) {
            columnScrollPosition.current = columnContainer.scrollTop;
        }
        setShowOverlay(true);
        setEditingTaskId(task.id);
        setTimeout(() => {
            document.querySelector('.kanban-overlay').classList.add('fade-in');
        }, 0);
    };

    const handleCancel = () => {
        document.querySelector('.kanban-overlay').classList.remove('fade-in');
        document.querySelector('.kanban-overlay').classList.add('fade-out');
        setTimeout(() => {
            setShowOverlay(false);
            setEditingTaskId(null);
            document.querySelector('.kanban-overlay')?.classList.remove('fade-out');
        }, 500);
        restoreColumnScrollPosition();
    };

    const restoreColumnScrollPosition = () => {
        const columnContainer = cardInnerRef.current.closest('.kanban-column');
        if (columnContainer) {
            columnContainer.scrollTo({
                top: columnScrollPosition.current,
                behavior: 'smooth',
            });
        }
    };

    if (!task) {
        return null;
    }

    return (
        <>
            {showOverlay && <div className="kanban-overlay" />}
            <div
                className={`kanban-card ${isEditing ? 'editing' : ''}`}
                style={{
                    ...borderStyle,
                    marginBottom: isLast ? '440px' : '0px',
                    filter: isHovered ? 'none' : `blur(${blurLevel}px)`, // Remove blur on hover
                    transition: 'filter 0.3s ease', // Smooth transition for blur
                }}
                ref={cardInnerRef}
                onMouseEnter={handleMouseEnter} // Set hover event
                onMouseLeave={handleMouseLeave} // Remove hover event
            >
                <div className="kanban-card-inner">
                    {!isEditing ? (
                        <KanbanCardFront
                            editableFields={editableFields}
                            columns={columns}
                            user={user}
                            handleEdit={handleEdit}
                        />
                    ) : (
                        <KanbanCardBack
                            task={task}
                            columns={columns}
                            editableFields={editableFields}
                            handleFieldChange={handleFieldChange}
                            handleCancel={handleCancel}
                            setEditableFields={setEditableFields}
                            fetchData={fetchData}
                            handleSave={handleSave}
                            handleDelete={handleDelete}
                            setEditingTaskId={setEditingTaskId}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default KanbanCard;
