// TeamStatus.js
import React, { useState, useEffect } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import KanbanCard from './cards/KanBanCardSmall';

const TeamStatus = ({ fetchData, selectedAssignee, data, columns, setData }) => {
    const [editingTaskId, setEditingTaskId] = useState(null);
    const [fadeClass, setFadeClass] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (selectedAssignee) {
            setIsVisible(true);
            setFadeClass('fade-in');
        } else if (isVisible) {
            setFadeClass('fade-out');
            setTimeout(() => {
                setIsVisible(false);
            }, 500);
        }
    }, [selectedAssignee]);

    if (!data || !data.rows || !columns || !isVisible) {
        return null;
    }

    const stateColumnId = Object.keys(columns).find(columnId => columns[columnId].title === "State");
    const assigneeColumnId = Object.keys(columns).find(columnId => columns[columnId].title === "Assignee");

    const inProgressTasks = data.rows.filter(row => {
        const stateCell = row.cells.find(cell => cell.columnId === parseInt(stateColumnId));
        const assigneeCell = row.cells.find(cell => cell.columnId === parseInt(assigneeColumnId));
        return stateCell?.value.toLowerCase() === "in progress" && assigneeCell?.value === selectedAssignee;
    });

    const handleDeleteTask = (taskId) => {
        setData(prevData => {
            const updatedRows = prevData.rows.filter(task => task.id !== taskId);
            return {
                ...prevData,
                rows: updatedRows
            };
        });
    };

    return (
        <div className={`team-status ${fadeClass}`} style={{ width: '100%' }}>
            <Droppable droppableId="team-status-tasks" direction="horizontal">
                {(provided) => (
                    <div
                        className="kanban-tasks"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                            display: 'flex',
                            gap: '10px',
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            padding: '10px 0',
                        }}
                    >
                        {inProgressTasks.length > 0 ? (
                            inProgressTasks.map((task, index) => (
                                <Draggable
                                    key={task.id}
                                    draggableId={String(task.id)}
                                    index={index}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                display: 'inline-block',
                                            }}
                                        >
                                            <KanbanCard
                                                key={task.id}
                                                task={task}
                                                columns={columns}
                                                isEditing={editingTaskId === task.id}
                                                setEditingTaskId={setEditingTaskId}
                                                fetchData={fetchData}
                                                handleDeleteTask={handleDeleteTask}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))
                        ) : null}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
};

export default TeamStatus;
