import React from 'react';
import Avatar from '../../../global/Avatar';

const KanbanCardFront = ({ editableFields, columns, user, handleEdit }) => {

    return (
        <div className="kanban-card-front" style={{borderColor:'red'}}>
            <h3 className="kanban-card-title">
                {editableFields[Object.keys(columns).find(columnId => columns[columnId].title === "Project")] || "No title provided"}
            </h3>

            <p className="kanban-card-description">
                {editableFields[Object.keys(columns).find(columnId => columns[columnId].title === "Description")] || "No description available"}
            </p>

            <div className="kanban-card-footer">
                {editableFields[Object.keys(columns).find(columnId => columns[columnId].title === "Business Unit")] && (
                    <span className="kanban-card-tag">
                        {editableFields[Object.keys(columns).find(columnId => columns[columnId].title === "Business Unit")]}
                    </span>
                )}
                {user && (
                    <span className="kanban-card-assignee">
                        <Avatar user={user}/>
                    </span>
                )}
            </div>
            <div className={"mg-t-4"}>
                {editableFields[Object.keys(columns).find(columnId => columns[columnId].title === "Product family")] && (
                    <span className="kanban-card-tag-small">
                        {editableFields[Object.keys(columns).find(columnId => columns[columnId].title === "Product family")]}
                    </span>
                )}
            </div>

            <button className='btn btn-sm edit-btn' onClick={handleEdit}>Edit</button>
        </div>
    );
};

export default KanbanCardFront;
