import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const KanbanCardBack = ({ task, columns, editableFields, handleFieldChange, setEditableFields, handleSave, handleCancel, handleDelete }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    // Function to add 14 days to a given date for the end date default
    const addTwoWeeks = (startDate) => {
        const date = new Date(startDate);
        date.setDate(date.getDate() + 14);
        return date;
    };

    // Function to format date from DatePicker to YYYY-MM-DD for Smartsheet
    const formatDateForSmartsheet = (date) => {
        return date ? date.toISOString().split('T')[0] : '';
    };

    // Function to parse date from Smartsheet format (YYYY-MM-DD) to a Date object
    const parseDateFromSmartsheet = (dateString) => {
        return dateString ? new Date(dateString) : null;
    };

    // Get today's date
    const getToday = () => {
        return new Date();
    };

    // Set default dates when the component mounts or task changes
    useEffect(() => {
        const startDateCell = task.cells.find(c => columns[c.columnId]?.title.toLowerCase().includes('start'));
        const endDateCell = task.cells.find(c => columns[c.columnId]?.title.toLowerCase().includes('end'));

        if (startDateCell && !editableFields[startDateCell.columnId]) {
            const today = getToday();
            setStartDate(today);
            handleFieldChange(startDateCell.columnId, formatDateForSmartsheet(today)); // Format for Smartsheet
        } else if (startDateCell && editableFields[startDateCell.columnId]) {
            setStartDate(parseDateFromSmartsheet(editableFields[startDateCell.columnId])); // Parse from Smartsheet format
        }

        if (endDateCell && !editableFields[endDateCell.columnId]) {
            const twoWeeksLater = addTwoWeeks(getToday());
            setEndDate(twoWeeksLater);
            handleFieldChange(endDateCell.columnId, formatDateForSmartsheet(twoWeeksLater)); // Format for Smartsheet
        } else if (endDateCell && editableFields[endDateCell.columnId]) {
            setEndDate(parseDateFromSmartsheet(editableFields[endDateCell.columnId])); // Parse from Smartsheet format
        }
    }, [task, columns]);

    const handleStartDateChange = (date) => {
        setStartDate(date);

        const startDateCell = task.cells.find(c => columns[c.columnId]?.title.toLowerCase().includes('start'));
        if (startDateCell) {
            handleFieldChange(startDateCell.columnId, formatDateForSmartsheet(date)); // Format for Smartsheet
        }

        const endDateCell = task.cells.find(c => columns[c.columnId]?.title.toLowerCase().includes('end'));
        if (endDateCell) {
            const newEndDate = addTwoWeeks(date);
            setEndDate(newEndDate);
            handleFieldChange(endDateCell.columnId, formatDateForSmartsheet(newEndDate)); // Format for Smartsheet
        }
    };

    const handleSelectChange = (cell, value) => {
        handleFieldChange(cell.columnId, value);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="kanban-card-back">
                {task?.cells?.filter(cell => columns[cell.columnId])
                    .map(cell => {
                        const column = columns[cell.columnId];

                        // Render a date picker for start/end date fields
                        if (column.title.toLowerCase().includes('start')) {
                            return (
                                <div key={cell.columnId} className="kanban-card-edit-field">
                                    <label>{column.title}</label>
                                    <DatePicker
                                        value={startDate}
                                        onChange={(date) => handleStartDateChange(date)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        className="kanban-card-input date-picker"
                                    />
                                </div>
                            );
                        }

                        if (column.title.toLowerCase().includes('end')) {
                            return (
                                <div key={cell.columnId} className="kanban-card-edit-field">
                                    <label>{column.title}</label>
                                    <DatePicker
                                        value={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                        className="kanban-card-input"
                                    />
                                </div>
                            );
                        }

                        // Render select options for columns with predefined options
                        if (column.options && column.options.length > 0) {
                            return (
                                <div key={cell.columnId} className="kanban-card-edit-field">
                                    <label>{column.title}</label>
                                    <select
                                        value={editableFields[cell.columnId] || ''}
                                        onChange={(e) => handleSelectChange(cell, e.target.value)}
                                        className="form-control select mg-r-8 mg-b-15 kanban-card-select"
                                    >
                                        <option value="">Unassigned</option> {/* Default N/A option */}
                                        {column.options.map(option => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                            );
                        }

                        // Render regular text input for other fields
                        return (
                            <div key={cell.columnId} className="kanban-card-edit-field">
                                <label>{column.title}</label>
                                <input
                                    type="text"
                                    value={editableFields[cell.columnId] || ''}
                                    onChange={(e) => handleFieldChange(cell.columnId, e.target.value)}
                                    className="kanban-card-input"
                                />
                            </div>
                        );
                    })}
                <div className="kanban-card-actions d-flex flex-column">
                    <button className="btn btn-sm btn-secondary" onClick={handleSave}>Save</button>
                    <button className="btn btn-sm" onClick={handleCancel}>Cancel</button>
                    <button className="btn btn-sm delete-button" onClick={handleDelete}>Delete</button>
                </div>
            </div>
        </LocalizationProvider>
    );
};

export default KanbanCardBack;
