import React, { useEffect, useState, useRef } from 'react';
import Avatar from '../../global/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './team.css';
import AUTH_BASE_URL from '../../../util/AUTH_BASE_URL';
import { Droppable } from 'react-beautiful-dnd';
import CapacityIcon from './CapacityIcon';
import { getGradientColors } from '../../../util/helpers/getGradientColors';

const Team = ({ assignees, selectedAssignee, onSelectAssignee, data, stateColumnId, assigneeColumnId }) => {
    const [users, setUsers] = useState({});
    const [cachedUsers, setCachedUsers] = useState({});
    const [teamList, setTeamList] = useState([]);
    const hasShuffled = useRef(false);

    useEffect(() => {
        if (!hasShuffled.current && assignees.length > 0) {
            const shuffledAssignees = shuffleArray(assignees);
            setTeamList(shuffledAssignees);
            hasShuffled.current = true;
        }
    }, [assignees, setTeamList]);

    useEffect(() => {
        assignees.forEach(assignee => {
            if (!cachedUsers[assignee.name]) {
                const firstName = assignee.name.split(' ')[0];
                fetch(`${AUTH_BASE_URL}auth/user/name?name=${firstName}`)
                    .then(response => {
                        if (!response.ok) throw new Error('Network response was not ok');
                        return response.json();
                    })
                    .then(data => {
                        setUsers(prevUsers => ({
                            ...prevUsers,
                            [assignee.name]: { ...data, capacity: Math.random() * 100 }
                        }));
                        setCachedUsers(prev => ({ ...prev, [assignee.name]: data }));
                    })
                    .catch(error => console.warn('Error fetching user:', error));
            } else {
                setUsers(prevUsers => ({
                    ...prevUsers,
                    [assignee.name]: { ...cachedUsers[assignee.name], capacity: Math.random() * 100 }
                }));
            }
        });
    }, [assignees, cachedUsers]);

    const shuffleArray = (array) => {
        const shuffled = [...array];
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    };

    const handleCardClick = (assigneeName) => {
        if (assigneeName === 'Close') {
            onSelectAssignee(null);
        } else {
            onSelectAssignee(assigneeName);
        }
    };

    // Helper function to calculate capacity for each assignee
    const calculateCapacity = (tasks, assigneeName, stateColumnId, assigneeColumnId, loeColumnId) => {
        const maxCapacity = 100; // Maximum capacity is 100%
        console.log('71', tasks, assigneeName, stateColumnId, assigneeColumnId, loeColumnId)
        // Filter tasks that are in progress and assigned to the current assignee
        const inProgressTasks = tasks.filter(task => {
            const stateCell = task.cells.find(cell => cell.columnId === stateColumnId);
            const assigneeCell = task.cells.find(cell => cell.columnId === assigneeColumnId);

            return stateCell?.value.toLowerCase() === "in progress" && assigneeCell?.value === assigneeName;
        });

        // Sum the level of effort for the filtered tasks
        const totalEffort = inProgressTasks.reduce((sum, task) => {
            const loeCell = task.cells.find(cell => cell.columnId === loeColumnId);
            const loeValue = loeCell ? parseInt(loeCell.value, 10) : 0;
            return sum + loeValue;
        }, 0);

        // Assume the maximum level of effort that can be handled is 21
        const maxEffort = 21;

        // Calculate the capacity percentage based on the total effort
        const capacityPercentage = Math.min((totalEffort / maxEffort) * maxCapacity, maxCapacity);

        return capacityPercentage;
    };

    // Example Level of Effort column ID (this should be passed as a prop or hardcoded if static)
    const loeColumnId = 1650935167405956;  // Replace with the actual column ID if it's dynamic

    const finalTeamList = selectedAssignee
        ? [...teamList, { name: 'Close', avatar: '' }]
        : teamList;

    return (
        <div className="team-component">
            <div className="team-list">
                {finalTeamList.map(assignee => {
                    if (assignee.name === 'Close') {
                        return (
                            <div
                                key={assignee.name}
                                style={{
                                    position: 'absolute',
                                    height: '30px',
                                    width: '30px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '10px',
                                    cursor: 'pointer',
                                    top: '50px',
                                    right: '50px',
                                    zIndex: 1000,
                                }}
                                onClick={() => handleCardClick(assignee.name)}
                            >
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    size="1x"
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        right: '0',
                                        padding: '5px',
                                        borderRadius: '50%',
                                        backgroundColor: 'transparent',
                                        color: '#333',
                                    }}
                                />
                            </div>
                        );
                    }

                    // Calculate capacity for the current assignee
                    const capacityPercentage = calculateCapacity(data.rows, assignee.name, stateColumnId, assigneeColumnId, loeColumnId);
                    const [startColor, endColor] = getGradientColors(assignee.name);

                    console.log('147', capacityPercentage)
                    return (
                        <Droppable droppableId={assignee.name} key={assignee.name}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{
                                        backgroundColor: snapshot.isDraggingOver ? '#e0e0e0' : 'transparent',
                                        display: 'inline-block',
                                        padding: '10px',
                                        borderRadius: '10px',
                                        marginBottom: '10px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <button
                                        key={assignee.name}
                                        className="team-card"
                                        style={{
                                            border: selectedAssignee === assignee.name
                                                ? `3px solid`
                                                : 'none',
                                            borderImage: selectedAssignee === assignee.name
                                                ? `linear-gradient(${startColor}, ${endColor}) 1`
                                                : 'none',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '100px',
                                            width: '100px',
                                            backgroundColor: selectedAssignee === assignee.name ? '#fff' : '#f9f9f9',
                                        }}
                                        onClick={() => handleCardClick(assignee.name)}
                                    >
                                        <Avatar user={users[assignee.name] || { name: assignee.name, avatar: assignee.avatar }} />
                                        <span style={{ paddingTop: '5px' }}>{assignee.name.split(' ')[0]}</span>
                                        <CapacityIcon capacity={capacityPercentage} startColor={'#333333'} endColor={ '#141414'} />
                                    </button>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    );
                })}
            </div>
        </div>
    );
};

export default Team;
